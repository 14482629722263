import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose, find, keys } from 'ramda';
import * as types from '../constants/prop-types';
import BasicLayout from './basic-layout';
import Button from '../components/button';
import FormInput from '../components/form-input';
import Svg from '../components/svg';
import { setField, clearForm, setValidationError, addCardAccount, getCardService } from '../actions/card-account-actions';
import { trackEvent } from '../utils/ganalytics';
import { getIconName, detectCardType } from '../constants/card-account-constants';

const CreditCardsAddPage = (props) => {
  useEffect(() => {
    props.clearForm();
    if(!props.service_id) {
      props.getCardService();
    }

    return () => {
      props.clearForm();
    };
  }, []);

  const inputRefs = {
    cardNumber: useRef(null),
    bankAccountNumber: useRef(null),
    bankAccountLabel: useRef(null),
  };

  useEffect(() => {
    const { validationErrors } = props;

    compose(
      find((ref) => {
        if(validationErrors[ref]) {
          const inputRef = inputRefs[ref].current;
          const inputElement = inputRef instanceof HTMLElement ? inputRef : inputRef.getInputDOMNode();
          const isInViewport = inputElement.getBoundingClientRect().y > 0;
  
          if(!isInViewport) {
            inputElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return true;
          }
        }
  
        return false;
      }),
      keys
    )(inputRefs);
  }, [props.validationErrors]);


  const {
    account_number,
    verification_code,
    expiration,
    holder_fname,
    holder_lname,
    zip_code,
    user_description,
  } = props.editingCard;
  const { validationErrors, headerClassName, wrapperClassName, addAndUse } = props;
  
  const addCardAccount = () => {
    trackEvent('create_credit_card', 'Click on Add Credit Card button');
    props.addCardAccount(addAndUse ? addAndUse : null);
  };


  return (
    <BasicLayout 
      innerWrapperClassName="cards-page__wrapper"
      headerClassName={headerClassName}
      wrapperClassName={wrapperClassName}
    >
      <main className="cards-page">
        <header>
          <h1>Add Credit Card</h1>
          <p>Credit card is used as a backup payment method for your transactions. Transactions with a backup method are processed faster.</p>
        </header>
        <div className="page_body">
          <div className="page_content cards-page__content">
            <form className="form" action="">
              <div className="layer full-width">
                <div className="form-input-container -account-number">
                  <FormInput
                    color="white"
                    inputName="account_number"
                    label="Card Number"
                    inputRef={inputRefs.cardNumber}
                    onChange={value => {
                      props.setValidationError('account_number', '');
                      props.setField(['editingCard','account_number'], value);
                    }}
                    value={account_number}
                    errorMessage={validationErrors.account_number}
                    invalid={!!validationErrors.account_number}
                    autoFocus
                    mask="9999 9999 9999 9999 9999 9999"
                    hideMask={true}
                  />
                  <Svg name={getIconName(detectCardType(account_number))} className="icon cards-page__icon " />
                </div>
              </div>
              <div className="layer -col -gap-5 full-width">
                <div className="form-input-container -grow">
                  <FormInput
                    color="white"
                    inputName="holder-fname"
                    label="First name on card"
                    onChange={value => {
                      props.setValidationError('holder_fname', '');
                      props.setField(['editingCard','holder_fname'], value);
                    }}
                    value={holder_fname}
                    errorMessage={validationErrors.holder_fname}
                    invalid={!!validationErrors.holder_fname}
                  />
                </div>
                <div className="form-input-container -grow">
                  <FormInput
                    color="white"
                    inputName="holder-lname"
                    label="Last name on card"
                    onChange={value => {
                      props.setValidationError('holder_lname', '');
                      props.setField(['editingCard','holder_lname'], value);
                    }}
                    value={holder_lname}
                    errorMessage={validationErrors.holder_lname}
                    invalid={!!validationErrors.holder_lname}
                  />
                </div>
              </div>
              <div className="layer -col -gap-5 full-width">
                <div className="form-input-container">
                  <FormInput
                    color="white"
                    inputName="expiration"
                    label="Valid through"
                    onChange={value => {
                      props.setValidationError('expiration', '');
                      props.setField(['editingCard','expiration'], value);
                    }}
                    value={expiration}
                    errorMessage={validationErrors.expiration}
                    invalid={!!validationErrors.expiration}
                    mask="99 / 99"
                  />
                </div>
                <div className="form-input-container">
                  <FormInput
                    color="white"
                    inputName="verification_code"
                    label="Security code"
                    onChange={value => {
                      props.setValidationError('verification_code', '');
                      props.setField(['editingCard','verification_code'], value);
                    }}
                    value={verification_code}
                    errorMessage={validationErrors.verification_code}
                    invalid={!!validationErrors.verification_code}
                    mask="9999"
                    hideMask={true}
                    type="password"
                  />
                </div>
                <div className="form-input-container">
                  <FormInput
                    color="white"
                    inputName="zip_code"
                    label="Zip code"
                    onChange={value => {
                      props.setValidationError('zip_code', '');
                      props.setField(['editingCard','zip_code'], value);
                    }}
                    value={zip_code}
                    errorMessage={validationErrors.zip_code}
                    invalid={!!validationErrors.zip_code}
                    mask="99999"
                  />
                </div>
              </div>
              <div className="layer full-width">
                <div className="form-input-container">
                  <FormInput
                    color="white"
                    inputName="user_description"
                    label="Card label"
                    onChange={value => {
                      props.setValidationError('user_description', '');
                      props.setField(['editingCard','user_description'], value);
                    }}
                    value={user_description}
                    errorMessage={validationErrors.user_description}
                    invalid={!!validationErrors.user_description}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="page_controls -align-top-desktop">
          <Button
            color="blue"
            xSize="full"
            className="js-submit-button page_control -submit"
            onClick={addCardAccount}
            disabled={props.isLoading}
            isLoading={props.isLoading}
          >
            Add Credit Card
          </Button>
        </div>
      </main>
    </BasicLayout>
  );
};

CreditCardsAddPage.propTypes = {
  isLoading: PropTypes.bool,
  setField: PropTypes.func,
  clearForm: PropTypes.func,
  setValidationError: PropTypes.func,
  addCardAccount: PropTypes.func,
  editingCard: types.EditingCardShape,
  validationErrors: PropTypes.objectOf(PropTypes.string),
  getCardService: PropTypes.func,
  service_id: PropTypes.number,
  headerClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  addAndUse: PropTypes.func
};

const mapStateToProps = (state, props) => ({
  ...state.cardAccount
});

export default connect(mapStateToProps, { setField, clearForm, setValidationError, addCardAccount, getCardService })(CreditCardsAddPage);